<template>
    <div class="lounge-request-introduction">
        <StackRouterHeaderBar :leftButton="'close'" :leftButtonHandler="back" />
        <div class="container">
            <img class="top-img" src="@/assets/images/lounge/lounge_request_introduction.png" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoungeRequestIntroductionPage',
    props: {
        agent: {
            type: Object,
            default: null,
        },
        beforeBackgroundColor: {
            type: String,
            default: '#FFFFFF',
        },
    },
    methods: {
        async init() {},
        back() {
            this.$stackRouter.pop()
        },
    },
    mounted() {},
    beforeDestroy() {},
}
</script>

<style lang="scss" scoped>
.lounge-request-introduction {
    height: 100vh;
    overflow: auto;

    .close-btn {
        position: fixed;
        left: 16px;
        top: 16px;
        color: black;
        z-index: 1;
        width: 28px;
        height: 28px;
        padding: 2px;
        border-radius: 14px;
        // background-color: rgba(0, 0, 0, 0.3);
    }

    .container {
        position: relative;

        img {
            width: 100vw;
        }

        .top-img {
            position: inherit;
            display: flex;
        }

        .blank {
            position: inherit;
            display: flex;
            width: 100vw;
            height: 80px;
        }
    }
}
</style>
